import React, {Component} from "react";
import ReactTwitchEmbedVideo from "react-twitch-embed-video";
import LaraItem from "./LaraItem";
import "./Styles/LampaFeed.css"

interface GamesListProps{
	dataLink:string;
}
interface GamesListStates{
	result:string[][]
	downloaded:boolean;
	opened:false;

}
class GamesList extends Component<GamesListProps, GamesListStates>{

	constructor(props: GamesListProps) {
		super(props);

		this.state = {
			downloaded:false,
			result:[],
			opened:false,
		}
	}

	componentDidMount() {

		this.downloadList();
	}

	downloadList() {

		if(this.state.downloaded)
			return

		fetch(this.props.dataLink)
			.then(response =>
			{
				response.text().then(text => {

					console.log(text)
					let newResult: string[][] = []
					let values = JSON.parse(text) as Array<Array<string>>;

					if(values == null || values.length == 0)
						return;

					values.forEach((value,index) => {
						if(index == 0)
							return;

						if(value != null) {
							newResult.push(value)

						}
					})

					newResult.forEach((value) => {
						console.log(value[0])
					})

					this.setState({downloaded:true, result:newResult});
					console.log(this)
				})
			}).catch(error => {
			console.log(error)
		})


	}

	gameElement(gameInfo: string[]){
		return <LaraItem {...gameInfo}/>
	}
	render() {


		let games = <>loading</>
		let gridClass = ""
		let record = <></>

		console.log("CURRENT STATE => " + this.state.downloaded)

		if (this.state.downloaded) {
			games = <>{this.state.result.map((value) => this.gameElement(value))}</>
			if(window.innerWidth >= 1350)
				gridClass = "games-grid"
		}

		console.log(games)

		return (<div className = "CardsHolder">
			<div className={gridClass}>{games}</div>
		</div>);
	}


}

export default GamesList;