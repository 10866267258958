import React, { Component } from "react";
import "./Styles/AchievementsList.css";

interface AchievementsListProps {
	steamId: string;
	gameId: string;
	chaptersTxt: string; // Передаем текст глав в формате .txt
}

interface Achievement {
	name: string; // API name
	achieved: boolean; // Получено или нет
	displayName: string; // Локализованное название
	description: string; // Локализованное описание
	icon: string; // URL иконки достижения
	unlockTime: string | null; // Дата получения
}

interface AchievementsListState {
	achievements: Achievement[];
	groupedAchievements: Record<string, Achievement[]>; // Достижения, сгруппированные по главам
	loaded: boolean;
}

class AchievementsList extends Component<AchievementsListProps, AchievementsListState> {
	constructor(props: AchievementsListProps) {
		super(props);
		this.state = {
			achievements: [],
			groupedAchievements: {},
			loaded: false,
		};
	}

	componentDidMount() {
		this.fetchAchievements();
	}

	parseChapters(txt: string): Record<string, string[]> {
		console.error(txt)
		const lines = txt.split("\n").filter((line) => line.trim() !== "");
		const chapters: Record<string, string[]> = {};
		let currentChapter = "";

		lines.forEach((line) => {
			if (line.startsWith("Глава")) {
				currentChapter = line.trim();
				chapters[currentChapter] = [];
			} else if (currentChapter) {
				chapters[currentChapter].push(line.trim());
			}
		});

		return chapters;
	}

	async fetchAchievements() {
		const apiKey = process.env.REACT_APP_STEAM_API_KEY; // Ваш API-ключ
		const { steamId, gameId, chaptersTxt } = this.props;

		try {
			// Получаем список достижений пользователя
			/*const playerAchievementsResponse = await fetch(
				`https://77.105.146.218:5000/api/achievements?steamId=${steamId}&appId=${gameId}`
			);*/

			const playerAchievementsResponse = await fetch(
				`https://swathingsoap.ru/steam-api/ISteamUserStats/GetPlayerAchievements/v1/?key=${apiKey}&steamid=${steamId}&appid=${gameId}`
			);
			const playerAchievementsData = await playerAchievementsResponse.json();

			// Получаем схему достижений для игры
		/*	const schemaResponse = await fetch(
				`https://77.105.146.218:5000/api/schema?steamId=${steamId}&appId=${gameId}`
			);*/

			const schemaResponse = await fetch(
				`https://swathingsoap.ru/steam-api/ISteamUserStats/GetSchemaForGame/v2/?key=${apiKey}&appid=${gameId}&l=russian`
			);
			const schemaData = await schemaResponse.json();

			// Группируем достижения по главам
			const chapters = this.parseChapters(chaptersTxt);

			console.error(chapters)

			const achievements = playerAchievementsData.playerstats.achievements.map((playerAch: any) => {
				const schemaAch = schemaData.game.availableGameStats.achievements.find(
					(ach: any) => ach.name === playerAch.apiname
				);

				const unlockTime = playerAch.achieved
					? new Date(playerAch.unlocktime * 1000).toLocaleDateString("ru-RU")
					: null;

				return {
					name: playerAch.apiname,
					achieved: playerAch.achieved === 1,
					displayName: schemaAch?.displayName || "Unknown Achievement",
					description: schemaAch?.description || "Скрытое достижение",
					icon: playerAch.achieved ? schemaAch?.icon : schemaAch?.icongray || "",
					unlockTime,
				};
			});

			// Сопоставляем достижения с главами
			const groupedAchievements: Record<string, Achievement[]> = {};
			Object.entries(chapters).forEach(([chapter, titles]) => {
				console.error(chapter)
				groupedAchievements[chapter] = titles.map((title) =>
						achievements.find((ach: Achievement) => ach.displayName === title) || {
							name: "",
							achieved: false,
							displayName: title,
							description: "Описание недоступно",
							icon: "",
							unlockTime: null,
						}
				);
			});

			this.setState({ achievements, groupedAchievements, loaded: true });
		} catch (error) {
			console.error("Error fetching achievements:", error);
		}
	}

	render() {
		const { groupedAchievements, loaded } = this.state;

		console.error(loaded)
		if (!loaded) {
			return <div style={{color: "white"}}>Loading achievements...</div>;
		}

		console.error(this.state.achievements)
		console.error(this.state.groupedAchievements)
		{
			for (let groupedAchievementsKey in this.state.groupedAchievements) {
				console.error(this.state.groupedAchievements[groupedAchievementsKey])
			}
		}

		let gridClass = ""
		if(window.innerWidth >= 1350)
			gridClass = "container-holder"

		return (


			<div className="CardsHolder"><div className={gridClass}>
				{Object.entries(groupedAchievements).map(([chapter, achievements], chapterIndex) => (
					<div className="container" style={
						{
							backgroundImage: `url("/Chapters/${chapter}.jpg")`,
							backgroundSize: "cover",
							backgroundPosition: "center",
						}} key={chapterIndex}>
						<div className="left-panel">
							<div className="header">{chapter}</div>
						</div>
						<div className="right-panel">
							{achievements.map((ach, achIndex) => (
								<div className="box" key={achIndex}>
									<img
										src={ach.icon || "https://via.placeholder.com/64"}
										alt={ach.displayName}
										className="achievement-icon"
									/>
									<div className="achievement-name">{ach.displayName}</div>
									<div className="achievement-description">{ach.description}</div>
									{ach.unlockTime && (
										<div className="achievement-unlocktime">
											Получено: {ach.unlockTime}
										</div>
									)}
								</div>
							))}
						</div>
					</div>
				))}
			</div></div>
		);
	}
}

export default AchievementsList;
