import React, {Component} from "react";
import './Styles/LampaFeed.css';
import LaraItem from "./LaraItem";
import ReactTwitchEmbedVideo from "react-twitch-embed-video"
import GamesList from "./GamesList";
import ShowButton from "../Buttons/ShowButton";
import AchievementsList from "../AchievementsList";
import  exampleText  from '../data.js';


class LampaFeed extends Component<any, any>{

	constructor() {
		super("");
		this.state = {
			downloaded:false,
			result:[]
		}
	}


	lara = <GamesList dataLink={process.env.REACT_APP_SPREADSHEET_LAMPA_LARA_GET as string}/>
	rezik = <GamesList dataLink={process.env.REACT_APP_SPREADSHEET_LAMPA_RESIDENT_GET as string}/>

	chapters = "";

	render() {

		{

			console.log(exampleText)
		}


		return (<div>
			<div className="StickyTop">
				<a id="topLink" href="https://lampafeed.ru/"><img className="Logo-holder"
																  src="./favicon.ico" alt="logo"/></a>
				<hr className="Hr"></hr>
				{/*<Wishlist/>*/}
			</div>
			<header className="App-header">
				<p>LAMPAFEED</p>
				<br/>
				<a style={{color: "white"}} href="https://www.twitch.tv/lampafeed">Twitch</a>
				<a style={{color: "white"}} href="https://vk.com/budkafeed">Вконтакте</a>
				<a style={{color: "white"}} href="https://www.youtube.com/@lampafeed">YouTube</a>
				<a style={{color: "white"}} href="https://vkplay.live/lampafeed">VKPlay</a>
				<a style={{color: "white"}} href="https://www.donationalerts.com/r/lampafeed">DonationAlerts</a>
				<br/>
			</header>

			{/*<div style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				width: "100%",
			}}>
				<div className="stream2">

					<div className="stream-container2">
						<iframe className="vk-frame" src="https://live.vkvideo.ru/app/embed/lampafeed" allowFullScreen
								frameBorder="0"
								height="378" width="620"></iframe>
					</div>

					<div className="chat-container2">
						<iframe className="vk-frame"
								src="https://www.twitch.tv/embed/lampafeed/chat?darkpopout&parent=lampafeed.ru"
								allowFullScreen>
						</iframe>
					</div>
				</div>
			</div>*/}

			<br/>
			<br/>
			<br/>

			<><ReactTwitchEmbedVideo channel="lampafeed" chat="mobile" layout="video" height="300" width="532"/><br/></>
			<br/>
			<p style={{color: "white"}}>Сейчас мы проходим</p>

			<ShowButton label="Марафон Tomb Raider" render={<><GamesList
				dataLink={process.env.REACT_APP_SPREADSHEET_LAMPA_LARA_GET as string}></GamesList><br/></>}/>
			<ShowButton label="Марафон Resident Evil"
						render={<><GamesList dataLink={process.env.REACT_APP_SPREADSHEET_LAMPA_RESIDENT_GET as string}/><br/></>}/>


			<ShowButton
				label="Платина Half-Life 2"
				render={
					<>
						<AchievementsList
							steamId="76561198058067012"
							gameId="220"
							chaptersTxt={exampleText}
						/>
						<br/>
					</>
				}
			/>


			<br/><br/><br/><br/><br/><br/>
		</div>);
	}


}


export default LampaFeed