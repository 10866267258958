const exampleText = "Глава 1\n" +
    "Дерзость\n" +
    "Покорность\n" +
    "Мятеж\n" +
    "\n" +
    "Глава 2\n" +
    "Какая кошка?\n" +
    "Надежный друг\n" +
    "\n" +
    "Глава 3\n" +
    "Боулинг с барнаклами\n" +
    "Поднять якорь!\n" +
    "\n" +
    "Глава 4\n" +
    "Месть!\n" +
    "Тяжелое оружие\n" +
    "Вортикашель\n" +
    "\n" +
    "Глава 5\n" +
    "Нулевой уровень\n" +
    "Воспоминания из прошлого\n" +
    "Двухочковый\n" +
    "\n" +
    "Глава 6\n" +
    "Зомбомет\n" +
    "Святая земля\n" +
    "\n" +
    "Глава 7\n" +
    "Где не справится даже Кэббедж\n" +
    "Один стоит целой армии\n" +
    "Нарушение техники безопасности\n" +
    "Говорящая реклама\n" +
    "\n" +
    "Глава 8\n" +
    "Ни шагу по песку!\n" +
    "Один стоит целой армии\n" +
    "Большая охота\n" +
    "\n" +
    "Глава 9\n" +
    "Крышка\n" +
    "\n" +
    "Глава 9а\n" +
    "Фримен, страж\n" +
    "\n" +
    "Глава 10\n" +
    "За Фрименом\n" +
    "Уровень радиации - высокий\n" +
    "Защитник площади\n" +
    "Атака хэков!\n" +
    "\n" +
    "Глава 11\n" +
    "Анти-снайпер\n" +
    "Борец с полем\n" +
    "Убийца титанов\n" +
    "Следопыт\n" +
    "\n" +
    "Глава 12\n" +
    "Дезинтегратор\n" +
    "\n" +
    "Глава 13\n" +
    "Коллапс сингулярности\n" +
    "\n"
export default exampleText;